var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:{
    notification: true,
    activeClass: _vm.activeClassroom,
    quizClass: _vm.classroom.type === 1,
    imageClass: _vm.classroom.type === 2,
    lectureClass: _vm.classroom.type === 0,
    comboClass: _vm.classroom.type === 3,
  }},[_c('p',{staticClass:"subtitle"},[_c('span',[_c('b-tooltip',{attrs:{"label":_vm.mGetClassroomTypeName(_vm.classroom.type)}},[_c('b-icon',{attrs:{"icon":_vm.mGetClassroomTypeIcon(_vm.classroom.type)}})],1)],1),_vm._v(" "+_vm._s(_vm.classroom.name)+" ")]),_c('hr'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns centered"},[_c('div',{staticClass:"column"},[_c('div',[_c('b-field',{staticClass:"pb-2"},[_c('p',{staticClass:"subtitle is-6"},[_c('strong',[_vm._v(_vm._s(_vm.t('classroom.teacher'))+":")]),_c('span',[_vm._v(" "+_vm._s(_vm.currentTeacher))])])])],1),_c('div',[_c('b-field',[_c('p',{staticClass:"subtitle is-6"},[_c('span',[_c('b-tooltip',{attrs:{"label":_vm.t('classroom.start')}},[_c('b-icon',{attrs:{"icon":"calendar-today"}})],1)],1),_vm._v(" "+_vm._s(_vm.mDateTime(_vm.classroom.starts))+" ")])])],1),_c('div',[_c('b-field',[_c('p',{staticClass:"subtitle is-6"},[_c('span',[_c('b-tooltip',{attrs:{"label":_vm.t('classroom.duration')}},[_c('b-icon',{attrs:{"icon":"clock"}})],1)],1),_vm._v(" "+_vm._s(_vm.timeClassroom)+" h ")])])],1)])]),(_vm.activeClassroom)?_c('b-field',[_c('b-button',{staticClass:"is-dark",attrs:{"icon-left":"door-open","size":"is-small","expanded":""},on:{"click":_vm.openClassroom}},[_vm._v(_vm._s(_vm.t('classroom.join')))])],1):_vm._e(),(_vm.classroom.type === 0 && _vm.oldLectur)?_c('b-button',{staticClass:"is-dark",attrs:{"size":"is-small","expanded":"","icon-left":"magnify"},on:{"click":_vm.showOldLecture}},[_vm._v(_vm._s(_vm.t('classroom.lectureNote')))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
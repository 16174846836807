<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false">
      <b-tab-item :label="t('componentNames.classrooms')" icon="file-multiple">
        <ClassroomStudent />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
import ClassroomStudent from '@/components/classroom/ClassroomStudentMetro'

export default {
  name: 'ClassroomsStudentView',
  components: {
    ClassroomStudent
  }
}
</script>

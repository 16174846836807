<template>
  <article
    :class="{
      notification: true,
      activeClass: activeClassroom,
      quizClass: classroom.type === 1,
      imageClass: classroom.type === 2,
      lectureClass: classroom.type === 0,
      comboClass: classroom.type === 3,
    }"
  >
    <p class="subtitle">
      <span>
        <b-tooltip :label="mGetClassroomTypeName(classroom.type)">
          <b-icon :icon="mGetClassroomTypeIcon(classroom.type)"></b-icon>
        </b-tooltip>
      </span>
      {{ classroom.name }}
    </p>
    <hr />
    <div class="content">
      <div class="columns centered">
        <div class="column">
          <div>
            <b-field class="pb-2">
              <p class="subtitle is-6">
                <strong>{{t('classroom.teacher')}}:</strong>
                <!-- <DetailTeacher v-if="classroom.teacher" :id="classroom.teacher">
                  <template v-slot:default="slotProps">
                    {{ slotProps.teacher }}
                  </template>
                </DetailTeacher>-->
                <span> {{ currentTeacher }}</span>
              </p>
            </b-field>
          </div>
          <div>
            <b-field>
              <p class="subtitle is-6">
                <span>
                  <b-tooltip :label="t('classroom.start')">
                    <b-icon icon="calendar-today"></b-icon>
                  </b-tooltip>
                </span>
                {{ mDateTime(classroom.starts) }}
              </p>
            </b-field>
          </div>
          <div>
            <b-field>
              <p class="subtitle is-6">
                <span>
                  <b-tooltip :label="t('classroom.duration')">
                    <b-icon icon="clock"></b-icon>
                  </b-tooltip>
                </span>
                {{ timeClassroom }} h
              </p>
            </b-field>
          </div>
        </div>
      </div>

      <b-field v-if="activeClassroom">
        <b-button
          @click="openClassroom"
          class="is-dark"
          icon-left="door-open"
          size="is-small"
          expanded
        >{{t('classroom.join')}}</b-button>
      </b-field>
      <b-button
        v-if="classroom.type === 0 && oldLectur"
        size="is-small"
        class="is-dark"
        @click="showOldLecture"
        expanded
        icon-left="magnify"
      >{{t('classroom.lectureNote')}}</b-button>
    </div>
  </article>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
//import DetailTeacher from "@/components/username/DetailTeacher";
import { Action } from "@/store/config_actions";
import moment from "moment";

export default {
  name: "ClassroomCard",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    //DetailTeacher,
  },

  props: {
    classroom: { type: Object, required: true },
    refreshTrigger: { type: Number, required: true },
  },

  data: function () {
    return {
      activeClassroom: null,
      classroomStudInterval: null,
      currentTeacher: "",
      //oldLectur: null,
    };
  },

  mounted() {
    this.activeClassroom = this.setActiveClassroom();
    this.getTeacher();
  },

  destroyed() { },

  watch: {
    refreshTrigger() {
      this.activeClassroom = this.setActiveClassroom();
    },
  },

  computed: {
    timeClassroom() {
      if (this.classroom) {
        let difference = moment(this.classroom.ends).diff(
          this.classroom.starts,
          "minutes"
        );
        const hours = Math.floor(difference / 60);
        let minutes = difference % 60;
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }

        return `${hours}:${minutes}`;
      }
      return "";
    },

    oldLectur() {
      return this.mComparerDate(
        this.mDateTimeFull(this.classroom.ends),
        this.mNow()
      );
    },
  },

  methods: {
    setActiveClassroom() {
      var isStarted = this.mComparerDate(
        this.mDateTimeFull(this.classroom.starts),
        this.mNow()
      );
      var isEnded = this.mComparerDate(
        this.mNow(),
        this.mDateTimeFull(this.classroom.ends)
      );

      if (isStarted && isEnded && this.classroom.startOnTime && !this.classroom.stopOnTime) {
        return true;
      } else {
        return false;
      }
    },

    openClassroom() {
      if (this.classroom.type === 0) {
        this.$router.push({
          name: "opensea",
          params: { classId: this.classroom.id },
        });
      } else if (this.classroom.type === 1) {
        this.$router.push({
          name: "studentexam",
          params: { idClassroom: this.classroom.id },
        });
      } else if (this.classroom.type === 2) {
        this.$router.push({
          name: "examimagestart",
          params: { idClassroom: this.classroom.id },
        });
      } else if (this.classroom.type === 3) {
        this.$router.push({
          name: "examcombostart",
          params: { idClassroom: this.classroom.id },
        });
      } else {
        this.mDangerSnack(this.t('validation.openUnknowTypeClassroom'));
      }
    },

    showOldLecture() {
      this.$router.push({
        name: "opensea",
        params: { classId: this.classroom.id, onlyShow: true },
      });
    },

    getTeacher() {
      this.$store
        .dispatch(Action.AUTH_GET_USER_NAME, this.classroom.teacher)
        .then((payload) => {
          this.currentTeacher = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.activeClass {
  background-color: #41b883;
}
.quizClass strong, .imageClass strong, .comboClass strong{
  color: white;
}
.quizClass, .imageClass, .comboClass{
  background-color:  #214c63; 
  color: white;
}
.lectureClass {
  background-color: lightyellow;
}
</style>
<template>
  <div>
    <div>
      <UserFeedback :target="'Lista zajęć - studenci'" class="end ml-3 mt-1"></UserFeedback>
    <b-field grouped>
      <b-field>
       <b-button
          :loading="isLoading"
          icon-left="refresh"
          @click="refresh"
          >{{t('common.refresh')}}
          </b-button
        >
      </b-field>
       <b-field :label="t('common.from')" label-position="on-border">
          <b-datepicker
            v-model="date.from"
            icon="calendar-today"
          ></b-datepicker>
        </b-field>
        <b-field :label="t('common.to')" label-position="on-border">
          <b-datepicker
            v-model="date.to"
            icon="calendar-today"
            :min-date="dateFrom"
            overflow: visible
          ></b-datepicker>
        </b-field>
      <b-field :label="t('common.searchInTitleAndTags')" label-position="on-border" expanded>
        <b-input
          :placeholder="t('common.search')"
          v-model="classroomWordSearch"
          type="search"
          expanded
        ></b-input>
      </b-field>
           
       
    </b-field>
      
      
      <b-field grouped group-multiline class="mb-5">
        <b-field>
            <b-checkbox v-model="showExamTest" class="mr-3">
              <span class="bold">{{t('classroom.types.examTest')}}</span>
            </b-checkbox>
            <b-checkbox v-model="showExamDigit" class="mr-3">
              <span class="bold">{{t('classroom.types.examMultimedia')}}</span>
            </b-checkbox>
            <b-checkbox v-model="showExamCombo" class="mr-3">
              <span class="bold">{{t('classroom.types.examCombo')}}</span>
            </b-checkbox>            
            <b-checkbox v-model="showLecture" class="mr-3">
              <span class="bold">{{t('classroom.types.lecture')}}</span>
            </b-checkbox>
          <b-checkbox v-model="oldClassroom" class="mr-3">
            <span class="bold">{{t('classroom.ended')}}</span>
          </b-checkbox>
          <b-checkbox v-model="activeClassroom" class="mr-3">
            <span class="bold">{{t('classroom.active')}}</span>
          </b-checkbox>
          <b-checkbox v-model="afterClassroom">
            <span class="bold">{{t('classroom.ongoing')}}</span>
          </b-checkbox>
        </b-field>
      </b-field>
    </div>
    <div class="columns is-multiline">
      <div
        class="column is-one-fifth"
        v-for="classroom in filteredClassrooms"
        :key="classroom.id"
      >
        <ClassroomStudentCard
          :classroom="classroom"
          @deleteClassroom="getStudentClassrooms"
          :refreshTrigger="refreshTrigger"
        ></ClassroomStudentCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Action } from "../../store/config_actions";
import CommonHelpers from "@/mixins/commons";
import ClassroomStudentCard from "./ClassroomStudentCard";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "ClassroomsMentor",
  mixins: [CommonHelpers],
  components: {
    ClassroomStudentCard,
    UserFeedback
  },

  props: {},

  data: function () {
    return {
      classrooms: [],
      isLoading: false,
      showExamTest: true,
      showExamDigit: true,
      showLecture: true,
      showExamCombo: true,
      classroomWordSearch: "",
      classroomStudInterval: null,
      refreshTrigger: 0,
      oldClassroom: false,
      activeClassroom: true,
      afterClassroom: true,
      date: {
        from: null,
        to: null,
      },
    };
  },

  mounted() {
    this.date.to = new Date(this.mNowPlusDays(30));
    this.date.from = new Date(this.mNowPlusDays(-10));
    this.classroomStudInterval = setInterval(() => {
      this.refreshTrigger++;
    }, 60000);
    this.getStudentClassrooms();
  },

  watch: {
    dateFrom() {
      if (this.date.to && this.date.from) {
        this.getStudentClassrooms();
      }
    },

    dateTo() {
      if (this.date.to && this.date.from) {
        this.getStudentClassrooms();
      }
    },
  },

  computed: {
    filteredClassrooms() {
      if (this.classrooms) {
        let filtered = this.classrooms;

        if (!this.showLecture) {
          filtered = filtered.filter((ex) => ex.type !== 0);
        }

        if (!this.showExamTest) {
          filtered = filtered.filter((ex) => ex.type !== 1);
        }

        if (!this.showExamDigit) {
          filtered = filtered.filter((ex) => ex.type !== 2);
        }

        if (!this.showExamCombo) {
          filtered = filtered.filter((ex) => ex.type !== 3);
        }

        if (!this.oldClassroom) {
          filtered = filtered.filter((ex) => this.mBeforeNow(ex.ends));
        }

        if (!this.activeClassroom) {
          filtered = filtered.filter(
            (ex) =>
              (this.mBeforeNow(ex.ends) && this.mAfterNow(ex.starts)) === false
          );
        }

        if (!this.afterClassroom) {
          filtered = filtered.filter((ex) => this.mAfterNow(ex.starts));
        }

        if (this.classroomWordSearch.length > 0) {
          filtered = filtered.filter(
            (ex) =>
              (ex.title && ex.title.includes(this.classroomWordSearch)) ||
              (ex.tags && ex.tags.includes(this.classroomWordSearch))
          );
        }

        filtered = filtered.sort(function (a, b) {
          return new Date(b.starts) - new Date(a.starts);
        });

        return filtered;
      } else return [];
    },

    dateFrom() {
      return new Date(this.date.from);
    },

    dateTo() {
      return new Date(this.date.to);
    },
  },

  destroyed() {
    clearInterval(this.classroomStudInterval);
  },

  methods: {
    onExamActiveChanged(exam) {
      if (exam) {
        this.addExam = false;
        this.activedExam = exam;
      }
    },

    savedExam(exam) {
      if (exam) {
        let baseExam = this.exams.find((q) => q.id === exam.id);
        if (baseExam) {
          this.$set(this.exams, this.exams.indexOf(baseExam), exam);
        } else {
          this.exams.push(exam);
        }
      }
    },

    refresh() {
      this.getStudentClassrooms();
      this.filteredClassrooms;
      this.refreshTrigger++;
    },

    getStudentClassrooms() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_STUDENT_CLASS, this.date)
        .then((payload) => {
          this.classrooms = payload;
          //this.getTeacherToClassroom();
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    // getTeacherToClassroom() {
    //   if (this.classrooms.length > 0) {
    //     let setingTeacher = this.classrooms.map((c)=> teacher)
    //     for (let index = 0; index < this.classrooms.length; index++) {
    //       const element = this.classrooms[index];
    //       console.log(index, element)
    //       this.getTeacher(element.teacher);
    //     }
    //   }
    // },

    // getTeacher(id) {
    //   //this.isLoading = true;
    //   let teacher = this.$store.state.teacher.names.find((t) => t.id === id);
    //   if (teacher == null) {
    //     //console.log("get user");
    //     this.$store
    //       .dispatch(Action.TEACHER_GET_NAME, id)
    //       .then(() => {
    //         // this.currentTeacher = payload;
    //         // this.isLoading = false;
    //       })
    //       .catch((error) => {
    //         this.mDangerSnack(error.toString());
    //         this.isLoading = false;
    //       });
    //   }
    // },
  },
};
</script>

<style scoped>
div.end{
  float: right;
}

.bold{
  font-weight: bold;
}
</style>